import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import {
  ClientMatterWithDetails,
  DataRoomClientFile,
  FolderConfigData,
  User,
} from '@/common/types';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';

export type IOverviewContext = {
  matter: ClientMatterWithDetails;
  user: User;
  isQueryInProgress: boolean;
  setIsQueryInProgress: (val: boolean) => void;
  userMessage: string;
  setUserMessage: (val: string) => void;
  setIsPanelCollapsed: (val: boolean) => void;
  isPanelCollapsed: boolean;
  selectedReferenceFocus: string;
  setSelectedReferenceFocus: (val: string) => void;
  selectedRefHighlight: number[];
  setSelectedRefHighlight: (highlight: number[]) => void;
  toolProgress: (
    startTime: Date | undefined,
    finishTime: Date | undefined,
    currentDate: Date | undefined,
  ) => number;
  getToolProcessing: (toolIdentifier: string) => {
    startTime: Date | undefined;
    finishTime: Date | undefined;
    complete: boolean;
  };
  checkForTool: (toolIdentifier: string) => boolean;
};

export const organizeDocumentsByPath = (files: DataRoomClientFile[]): FilesByPath => {
  const filesByPath: FilesByPath = {};

  files.forEach((file) => {
    const folderName = file.path && file.path.length ? file.path : '/';
    if (!filesByPath[folderName]) {
      filesByPath[folderName] = [file];
    } else {
      filesByPath[folderName].push(file);
    }
  });

  return filesByPath;
};

export const formatDate = (inputDate: Date | null) => {
  if (inputDate === null) return null;
  const date = new Date(inputDate);
  /** Date time comes through as UDT then converted to EDT, results in date being behind one day.
   * Converted date must be advanced by 1 day. **/
  date.setDate(date.getDate() + 1);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}.${month}.${day}`;

  return formattedDate;
};

export const formatFileDisplay = (
  file: Pick<DataRoomClientFile, 'name' | 'date' | 'company' | 'subject' | 'docTitle'>,
  template: string | null | undefined,
) => {
  if (!template) {
    template = '<<date>> <<company_name>> - <<document_title>> (<<counterparties>>)';
  }
  let renamedFile: string = '';
  const extension = '.' + file.name.split('.').slice(-1)[0];
  const formattedDate = formatDate(file.date);
  const hasDate = formattedDate !== null;
  const hasCompany = file.company !== null;
  const hasSubject = file.subject !== '' && file.subject !== null;
  renamedFile = template.replace('<<date>>', hasDate ? formattedDate : '');
  renamedFile = renamedFile.replace('<<company_name>>', hasCompany ? file.company || '' : '');
  renamedFile = renamedFile.replace('<<document_title>>', file.docTitle || '');
  renamedFile = renamedFile.replace('<<counterparties>>', hasSubject ? file.subject || '' : '');

  // remove paired parens, brackets, and braces
  let output = renamedFile;
  while (output != (output = output.replaceAll(/\(\s*\)|\[\s*\]|\{\s*\}/g, '')));
  renamedFile = output;

  // remove invalid starting and ending characters
  while (
    renamedFile.length > 0 &&
    !/[a-zA-Z0-9)]/g.test(renamedFile.charAt(renamedFile.length - 1))
  ) {
    renamedFile = renamedFile.substring(0, renamedFile.length - 1);
  }
  while (renamedFile.length > 0 && !/[a-zA-Z0-9(]/g.test(renamedFile.charAt(0))) {
    renamedFile = renamedFile.substring(1, renamedFile.length);
  }

  // remove doubled whitespace
  renamedFile = renamedFile.replaceAll(/\s+/g, ' ');

  // split into segments
  if (file.docTitle) {
    renamedFile = renamedFile.replace(file.docTitle, '<<<' + file.docTitle + '<<<');
  }
  if (hasDate) {
    renamedFile = renamedFile.replace(formattedDate, '<<<' + formattedDate + '<<<');
  }
  if (hasCompany && file.company) {
    renamedFile = renamedFile.replace(file.company, '<<<' + file.company + '<<<');
  }
  if (hasSubject && file.subject) {
    renamedFile = renamedFile.replace(file.subject, '<<<' + file.subject + '<<<');
  }
  const segments = renamedFile.split('<<<');
  const revisedSegments = segments.map((seg) => {
    let newSeg = seg;
    // consider segments without any alphanumeric characters
    if (newSeg.split('').every((char) => !/[a-zA-Z0-9]/g.test(char))) {
      if (newSeg.length > 0) {
        let startsWithParen = false;
        let endsWithParen = false;
        let startsWithSpace = false;
        let endsWithSpace = false;
        let connector = '';
        if (newSeg.charAt(0) === ')') {
          startsWithParen = true;
          newSeg = newSeg.slice(1);
        }
        if (newSeg.length > 0 && newSeg.charAt(newSeg.length - 1) === '(') {
          endsWithParen = true;
        }
        if (newSeg.length > 0 && newSeg.charAt(0) === ' ') {
          startsWithSpace = true;
          newSeg = newSeg.trimStart();
        } else {
          if (newSeg.length === 0 || newSeg.charAt(0) === '(') {
            connector = '';
          } else {
            connector = newSeg.charAt(0);
          }
        }
        if (newSeg.length > 0 && newSeg.charAt(0) !== '(') {
          connector = newSeg.charAt(0);
        }
        if (newSeg.length > 1 && newSeg.charAt(1) === ' ') {
          endsWithSpace = true;
        }
        if (connector != ' ') {
          newSeg =
            (startsWithParen ? ')' : '') +
            (startsWithSpace ? ' ' : '') +
            connector +
            (endsWithSpace ? ' ' : '') +
            (endsWithParen ? '(' : '');
        }
      }
    }
    return newSeg;
  });
  renamedFile = revisedSegments.join('');

  // remove invalid starting and ending characters again
  while (
    renamedFile.length > 0 &&
    !/[a-zA-Z0-9)]/g.test(renamedFile.charAt(renamedFile.length - 1))
  ) {
    renamedFile = renamedFile.substring(0, renamedFile.length - 1);
  }
  while (renamedFile.length > 0 && !/[a-zA-Z0-9(]/g.test(renamedFile.charAt(0))) {
    renamedFile = renamedFile.substring(1, renamedFile.length);
  }

  // this should never happen but will prevent empty filenames
  if (renamedFile.length === 0) {
    renamedFile = 'Unknown';
  }

  renamedFile = renamedFile + (extension && extension.charAt(0) === '.' ? `${extension}` : '');
  return renamedFile;
};

export const checkFileNameFormat = (
  files: Pick<DataRoomClientFile, 'name' | 'displayName' | 'originalPathname'>[],
) => {
  const allOriginal = files.every((file) => file.originalPathname.includes(file.displayName));
  const allMarveri = files.every((file) => !file.originalPathname.includes(file.displayName));

  if (allOriginal) return 'ORIGINAL';
  else if (allMarveri) return 'MARVERI';
  else return 'MIXED';
};

export const useOrganizeDocumentsByPath = (dataRoomFiles: DataRoomClientFile[]) => {
  return useMemo(() => organizeDocumentsByPath(dataRoomFiles), [dataRoomFiles]);
};

export const useFilesAndEmptyFolders = (
  dataRoomFiles: DataRoomClientFile[],
  folderConfig: FolderConfigData,
) => {
  const pathOrganizedDocuments = useOrganizeDocumentsByPath(dataRoomFiles);

  const filesAndEmptyFolders = useMemo(() => {
    const filesByPath = pathOrganizedDocuments;
    const folders = folderConfig?.emptyFolders || [];
    folders.forEach((folder) => {
      if (!Object.keys(filesByPath).includes(folder)) {
        filesByPath[folder] = [];
      }
    });
    return filesByPath;
  }, [pathOrganizedDocuments, folderConfig?.emptyFolders]);

  return filesAndEmptyFolders;
};

export type DropdownItems = {
  icon?: string;
  title: string;
  onClick: () => void;
};

export function useOverview() {
  return useOutletContext<IOverviewContext>();
}
