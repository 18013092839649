export const MARVERI_API_HTTP_BASE_URL =
  import.meta.env.VITE_MARVERI_API_HTTP_BASE_URL || `http://localhost:3000`;
export const MARVERI_API_WS_BASE_URL =
  import.meta.env.VITE_MARVERI_API_WS_BASE_URL || `ws://localhost:3000`;
export const LAUNCH_DARKLY_CLIENT_SIDE_KEY =
  import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_KEY || '6579c291856a2410b32924c9';
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN || '';
export const LOGROCKET_PROJECT_ID =
  import.meta.env.VITE_LOGROCKET_PROJECT_ID || 'onsisa/marveri-app-sandbox';

export const AUTH0_AUDIENCE =
  import.meta.env.VITE_AUTH0_AUDIENCE || 'https://api-development.marveri.com';
export const AUTH0_CLIENT_ID =
  import.meta.env.VITE_AUTH0_CLIENT_ID || 'knggOuPXZx5mAOudl2clHUp0UNNaUlqc';
export const AUTH0_DOMAIN =
  import.meta.env.VITE_AUTH0_DOMAIN || 'dev-bhrqs0gztehqnw5i.us.auth0.com';
export const MAX_POSIX_FILE_LENGTH = import.meta.env.MAX_POSIX_FILE_LENGTH || 240;
export const MAX_POSIX_PATH_LENGTH = import.meta.env.MAX_POSIX_PATH_LENGTH || 4000;
export const MAX_WINDOWS_FILE_LENGTH = import.meta.env.MAX_WINDOWS_FILE_LENGTH || 210;
export const MAX_WINDOWS_PATH_LENGTH = import.meta.env.MAX_WINDOWS_PATH_LENGTH || 210;
export const NAME_TRUNCTATION_RETAIN_END_LENGTH = 8;
export const FOLDER_NAME_TRUNCATION_MINIMUM_RETENTION_LENGTH = 12;
export const MAX_PATH_LENGTH_TRUNCATION_THRESHOLD_PERCENT = 0.75;

export const ENVIRONMENT =
  MARVERI_API_HTTP_BASE_URL === 'https://api.marveri.com'
    ? 'production'
    : MARVERI_API_HTTP_BASE_URL === 'https://api-staging.marveri.com'
      ? 'staging'
      : MARVERI_API_HTTP_BASE_URL === 'https://api-sandbox.marveri.com'
        ? 'sandbox'
        : 'test';

export const BYTES_IN_MB = 1024 * 1024;
export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * BYTES_IN_MB;
