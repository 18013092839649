import { Dialog, DialogPanel } from '@headlessui/react';
import { useEffect } from 'react';

interface UnsavedChangesModalProps {
  isUnsavedChangesModalOpen: boolean;
  setIsUnsavedChangesModalOpen: (val: boolean) => void;
  setIsSettingModalOpen: (val: boolean) => void;
}

export const UnsavedChangesModal = ({
  isUnsavedChangesModalOpen,
  setIsUnsavedChangesModalOpen,
  setIsSettingModalOpen,
}: UnsavedChangesModalProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setIsUnsavedChangesModalOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setIsUnsavedChangesModalOpen]);

  const handleModalClose = () => {
    setIsUnsavedChangesModalOpen(false);
  };

  const handleCloseAnyway = () => {
    setIsUnsavedChangesModalOpen(false);
    setIsSettingModalOpen(false);
  };

  return (
    <Dialog
      open={isUnsavedChangesModalOpen}
      onClose={handleModalClose}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="unsaves-changes-modal"
    >
      <DialogPanel
        className={`h-[202px] w-[466px] cursor-default rounded-[12px] bg-container-default p-[24px] text-marveri-white`}
      >
        <div className="flex flex-col gap-[12px]">
          <h1 className="text-[20px] font-semibold">Hold on!</h1>
          <span className="text-[16px] font-medium">
            You have unsaved changes in Settings. If you close Settings, these changes will be
            discarded.
          </span>
        </div>
        <div className="my-[18px] w-full border border-[#303030]" />
        <div className="flex justify-end gap-[8px] text-[12px]">
          <div
            className="flex h-[30px] w-[115px] cursor-pointer items-center justify-center rounded-[28px] bg-marveri-white px-[10px] py-[12px] text-[#121112] hover:bg-[#F8F5EE]"
            onClick={() => setIsUnsavedChangesModalOpen(false)}
          >
            Back to Settings
          </div>
          <div
            className="flex h-[30px] w-[115px] cursor-pointer items-center justify-center  px-[10px] py-[12px] hover:text-[#F8F5EE]"
            onClick={handleCloseAnyway}
          >
            Close anyway
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
