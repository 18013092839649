import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Refresh from '@/assets/icons/refresh.svg';
import Success from '@/assets/icons/success.svg';
import Warning from '@/assets/icons/warning.svg';
import { User } from '@/common/types';
import { formatFileDisplay } from '@/pages/overview/common/utils';
import { InputStateType } from '@/pages/settings/Settings';
import { trpcReact } from '@/utils/trpc';

const DEFAULT_TEMPLATE_VALUE =
  '<<date>> <<company_name>> - <<document_title>> (<<counterparties>>)';

interface NamingTemplateProps {
  inputState: InputStateType;
  setInputState: React.Dispatch<React.SetStateAction<InputStateType>>;
  user: User;
}

export const NamingTemplate = ({ user, inputState, setInputState }: NamingTemplateProps) => {
  const [newTemplate, setNewTemplate] = useState<string>(DEFAULT_TEMPLATE_VALUE);
  const [exampleText, setExampleText] = useState<string>('');

  useEffect(() => {
    setNewTemplate(user?.namingTemplate || DEFAULT_TEMPLATE_VALUE);
    setInputState({
      saveStatus: 'SAVED',
      validationStatus: 'VALID',
    });
    setExampleText(
      formatFileDisplay(
        {
          date: new Date('2020-06-15T12:00:00Z'),
          company: 'Certus, Inc.',
          docTitle: 'SAFE - $15M Cap; $200k',
          subject: 'Yardstick VC, LLC',
          name: 'Does not matter.pdf',
        },
        user?.namingTemplate || DEFAULT_TEMPLATE_VALUE,
      ),
    );
  }, [setInputState, user]);

  const updateTemplate = trpcReact.user.updateNamingTemplate.useMutation();

  const saveNewTemplate = useCallback(() => {
    setInputState((inputState) => {
      return {
        ...inputState,
        saveStatus: 'SAVING',
      };
    });
    updateTemplate.mutate(
      { newTemplate },
      {
        onSuccess: () => {
          setInputState({
            saveStatus: 'RESAVED',
            validationStatus: 'VALID',
          });
          toast.success(
            <span>
              Naming convention saved. Re-run <strong>Rename</strong> tool to apply.
            </span>,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            },
          );
        },
      },
    );
  }, [newTemplate, setInputState, updateTemplate]);

  const handleValidation = useCallback(
    (newValue: string) => {
      const testTemplate = newValue.replaceAll(/<<.+?>>/g, '');
      if ((testTemplate.endsWith('.') || testTemplate.endsWith(' ')) && !newValue.endsWith('>>')) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_TERMINAL',
          };
        });
      } else if (
        ['<', '>', ':', '"', '/', '\\', '|', '?', '*'].some((char) => testTemplate.includes(char))
      ) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_CHARACTER',
          };
        });
      } else if (testTemplate.length > 30) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_LENGTH',
          };
        });
      } else {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'VALID',
          };
        });
        setExampleText(
          formatFileDisplay(
            {
              date: new Date('2020-06-15T12:00:00Z'),
              company: 'Certus, Inc.',
              docTitle: 'SAFE - $15M Cap; $200k',
              subject: 'Yardstick VC, LLC',
              name: 'Does not matter.pdf',
            },
            newValue,
          ),
        );
      }
    },
    [setInputState],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewTemplate(event.target.value);
      setInputState((inputState) => {
        return {
          ...inputState,
          saveStatus: 'UNSAVED',
        };
      });
      handleValidation(event.target.value);
    },
    [handleValidation, setInputState],
  );

  const saveText = useMemo(() => {
    return {
      SAVED: (
        <>
          <img src={Success} />
          Saved
        </>
      ),
      UNSAVED: (
        <>
          <img src={Warning} />
          Unsaved
        </>
      ),
      SAVING: (
        <>
          <img src={Refresh} />
          Saving...
        </>
      ),
      RESAVED: (
        <>
          <img src={Success} />
          Saved - Run Rename tool to apply
        </>
      ),
    }[inputState.saveStatus];
  }, [inputState.saveStatus]);

  const validationText = useMemo(() => {
    return {
      VALID: null,
      INVALID_TERMINAL: (
        <span className="text-[#FF3B30]">
          Oops! It looks like that character isn&apos;t allowed at the end of the name.
        </span>
      ),
      INVALID_CHARACTER: (
        <span className="text-[#FF3B30]">
          Oops! It looks like that character isn&apos;t allowed.
        </span>
      ),
      INVALID_LENGTH: (
        <span className="text-[#FF3B30]">
          Oops! It looks like there are too many extra characters.
        </span>
      ),
    }[inputState.validationStatus];
  }, [inputState.validationStatus]);

  const saveDisabled = useMemo(() => {
    return inputState.saveStatus !== 'UNSAVED' || inputState.validationStatus !== 'VALID';
  }, [inputState.saveStatus, inputState.validationStatus]);

  const highlightedExampleText = useMemo(() => {
    let splittable = exampleText;
    const substrings = [
      'Certus, Inc.',
      '2020.06.16',
      'SAFE - $15M Cap; $200k',
      'Yardstick VC, LLC',
    ];
    substrings.forEach((substring) => {
      splittable = splittable.replace(substring, '<<<' + substring + '<<<');
    });
    return splittable.split('<<<');
  }, [exampleText]);

  const colorMap = {
    'Certus, Inc.': 'text-[#32ADE6]',
    '2020.06.16': 'text-[#00C7BE]',
    'SAFE - $15M Cap; $200k': 'text-[#5856D6]',
    'Yardstick VC, LLC': 'text-[#FF2D55]',
  } as { [key: string]: string };

  return (
    <div className="cursor-default" data-testid="document-naming">
      <h1 className="mb-[16px] text-[18px] font-semibold">Document Naming Convention</h1>
      <div className="mb-[32px] text-[12px] text-N200">
        Customize your naming convention by editing the field below.
      </div>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row whitespace-pre text-black">
          {highlightedExampleText.map((frag, idx) => {
            return (
              <span className={colorMap[frag] ?? 'text-white'} key={`fragment-${idx}`}>
                {frag}
              </span>
            );
          })}
        </div>
        <div className="flex flex-col gap-[4px] text-black">
          <input value={newTemplate} onChange={handleChange}></input>
        </div>
        <div className="flex flex-row gap-[4px] text-[10px] text-N400">
          {validationText ?? saveText}
        </div>
        <div className="flex flex-col items-end gap-[4px]">
          <button
            className={`h-[38px] w-[69px] ${saveDisabled ? 'cursor-not-allowed text-N600' : 'cursor-pointer text-[#EAE6EA]'} rounded-[30px] bg-N700 text-[14px] `}
            disabled={saveDisabled}
            onClick={saveNewTemplate}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
